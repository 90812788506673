<template>
    <div class="wrapper">

        <div class="body" style="padding:0;">
            <Slider></Slider>
            <!-- End Of Page Header -->
            <div class="self-exclusion-wrapper">
                <div class="container">

                    <div class="self-exclusion">
                        <h3 class="text-dark">Exclusion from Betting</h3>
                        <p class="text-dark">
                            This self exclusion page provides you with the option to take a
                            break from gambling activities for a specific period of time.
                            By utilising this feature, you can restrict your access to our
                            platform preventing yourself from placing bets and engaging in
                            gambling activities
                        </p>
                        <h6 class="text-dark">Your phone number is: +{{ myProfile.msisdn }}</h6>
                        <p></p>
                        <div class="select-period">

                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12 text-dark">
                            <form>
                                <h6 class="text-dark">Select Period of exclusion</h6>

                                <select v-model="duration" class="text-dark form-control"
                                    style="width: 100%;border: solid black 1px;">
                                    <option selected>Select Period</option>
                                    <option value="30">1 Month</option>
                                    <option value="180">6 Months</option>
                                    <option value="360">12 Months</option>
                                    <option value="720">24 Months</option>
                                    <option value="1080">36 Months</option>
                                    <option value="1440">48 Months</option>
                                    <option value="1800">60 Months</option>
                                </select>
                                <br />

                                <h6 class="text-dark">Reason for exclusion</h6>

                                <textarea v-model="reason" class="text-dark form-control" rows="4" cols="50"
                                    style="width: 100%;border: solid black 1px;">
                                </textarea>
                                <br />
                                <button @click.prevent="selfExclude"
                                    class="btn btn-block btn-lg btn-danger mb-2 w-100 p-1">
                                    Exclude me from Betting
                                </button>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Slider from '@/components/Slider.vue'
import axios from "@/services/identity";


export default {
    name: 'SelfExclusion',
    components: {
        Slider,
    },
    data: function () {
        return {
            myProfile: this.getProfile(),
            duration: '',
            reason: '',
        }
    },
    mounted() {
        var vm = this;
        vm.myProfile = vm.getProfile();

        this.reloadProfile();

        if (!vm.myProfile) {
            this.$toast.open({
                message: 'Please login to proceed',
                type: 'error',
                position: 'top'
            });
            this.$router.push({ name: "login", params: {} });
            return;
        }

        setInterval(function () {
            vm.myProfile = vm.getProfile();
        }, 1000);
    },
    methods: {
        selfExclude: function () {
            var vm = this;
            var path = "/profile/exclude?lang=en";

            var payload = {
                duration: parseInt(this.duration),
                profileID: parseInt(this.myProfile.id),
                reason: this.reason,
            };

            console.log("Request Payload:", payload);

            axios.patch(path, payload, {
                headers: {
                    'Content-Type': 'application/json',
                    'accept': 'application/json',
                    'api-key': vm.getAuth()
                }
            })
                .then(res => {
                    vm.spinner = false;
                    vm.loading = '';

                    console.log(JSON.stringify(res));

                    this.$toast.open({
                        message: 'Your account has been successfully deactivated',
                        type: 'success',
                        position: 'top'
                    });
                })
                .catch(err => {
                    vm.spinner = false;
                    console.log(JSON.stringify(err));

                    if (err.response) {
                        const errorMessage = err.response.data.error_message || 'An error occurred';
                        const errorCode = err.response.data.error_code;

                        if (err.response.status === 400) {
                            this.$toast.open({
                                message: `Invalid payload or missing field: ${errorMessage}`,
                                type: 'error',
                                position: 'top'
                            });
                        } else if (err.response.status === 401) {
                            this.$toast.open({
                                message: `Authorization error: ${errorMessage}`,
                                type: 'error',
                                position: 'top'
                            });
                        } else if (err.response.status === 403) {
                            this.$toast.open({
                                message: `Forbidden: ${errorMessage}`,
                                type: 'error',
                                position: 'top'
                            });
                        } else if (err.response.status === 500) {
                            this.$toast.open({
                                message: `Internal server error: ${errorMessage}`,
                                type: 'error',
                                position: 'top'
                            });
                        } else {
                            this.$toast.open({
                                message: errorMessage,
                                type: 'error',
                                position: 'top'
                            });
                        }

                        console.log(`Error Code: ${errorCode}, Message: ${errorMessage}`);
                    } else if (err.request) {
                        this.$toast.open({
                            message: 'Check your network connection and try again',
                            type: 'error',
                            position: 'top'
                        });
                        console.log(JSON.stringify(err.request));
                    } else {
                        this.$toast.open({
                            message: 'Check your network connection and try again',
                            type: 'error',
                            position: 'top'
                        });
                        console.log(JSON.stringify(err));
                    }
                });

        },
    }


};
</script>


<style scoped>
select.shadow-none {
    width: auto;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #fff;
    font-size: 12px;
    color: #333;
    box-shadow: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23333" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>');
    background-repeat: no-repeat;
    background-position-x: 100%;
    background-position-y: 50%;
}

select.shadow-none:focus {
    outline: none;
    border-color: #8faf37;
}

select.shadow-none option {
    padding: 10px;
    font-size: 11px;
    color: #333;
}
</style>